@import "../../styles/variables.scss";

.container {
  display: table;
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  transform: translateX(-50%) translateY(-50%);
}
.cover {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100vw;
  min-height: 100vh;
  width: auto;
  height: auto;
  z-index: -99;
  transform: translateX(-50%) translateY(-50%);
  // background: rgba(8, 32, 128, 0.85);
  background-color: #00b1ff;
}

.background {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100vw;
  min-height: 100vh;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  background: #01040d url("../../images/terrafinder.jpg") no-repeat;
  background-size: cover;
  background-position: center;
}

.content {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  input {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 640px;
    font-size: 22px;
  }
}

.logo {
  margin-top: -130px;
  padding-bottom: 80px;
  > img {
    width: 264px;
    height: 34px;
    @media (max-width: $breakpoint) {
      width: 196px;
      height: 25px;
    }
  }
  @media (max-width: $breakpoint) {
    margin-top: -40px;
    padding-bottom: 40px;
  }
}

.search {
  margin-bottom: -130px;
  width: 640px;
  max-width: 640px;
  padding: 0.5rem 1rem;
  input {
    font-weight: 300;
    height: 50px;
  }
  button {
    height: 49px;
  }
  button i {
    font-size: 24px;
  }
  @media (max-width: $breakpoint) {
    display: block;
    margin: 0 auto;
    width: 100%;
    input {
      background: transparent;
      border-bottom: 1px solid rgba(255, 255, 255, 0.9);
      color: #fff;
      font-size: 16px;
      height: 45px;
      font-weight: 400;
      padding-left: 5px !important;
      border-radius: 0 !important;
    }
    input::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.3);
    }
    input:-moz-placeholder {
      color: rgba(255, 255, 255, 0.3);
    }
    input::-moz-placeholder {
      color: rgba(255, 255, 255, 0.3);
    }
    input:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.3);
    }
    button {
      height: 44px;
    }
    button i {
      font-size: 21px;
      color: #fff;
    }
  }
}
