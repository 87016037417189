@import "../styles/variables.scss";

input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
  @media (max-width: $breakpoint) {
    color: rgba(32, 67, 181, 0.3);
  }
}
input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.3);
  @media (max-width: $breakpoint) {
    color: rgba(32, 67, 181, 0.3);
  }
}
input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.3);
  @media (max-width: $breakpoint) {
    color: rgba(32, 67, 181, 0.3);
  }
}
input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
  @media (max-width: $breakpoint) {
    color: rgba(32, 67, 181, 0.3);
  }
}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
  @media (max-width: $breakpoint) {
    display: inline;
  }
}

input[type="search"] {
  -webkit-appearance: none;
}

input::-webkit-search-decoration {
  -webkit-appearance: none;
}

input {
  width: 100%;
  border: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.9);
  font-size: 16px;
  height: 40px;
  color: #fff;
  background: transparent;
  padding-left: 10px !important;
  padding-right: 45px !important;
  border-radius: 0;
  word-break: normal;
  @media (max-width: $breakpoint) {
    border-radius: 0;
    background: #fff;
    color: $brand;
    border-bottom: solid 1px hsl(220 89% 11% / 0.6);
    padding-left: 5px !important;
    padding-right: 35px !important;
  }
}

.button {
  position: absolute;
  right: 0;
  bottom: 1px;
  height: 39px;
  outline: 0;
  cursor: pointer;
  margin: 0;
  i {
    color: #fff;
    padding: 0 5px 0 10px;
    font-size: 21px;
    line-height: 39px;
    @media (max-width: $breakpoint) {
      color: $brand;
      padding: 0 3px 0 5px;
      font-size: 19px;
      line-height: 42px;
    }
  }
}

form {
  display: inline-flex;
  min-height: 40px;
  @media (max-width: $breakpoint) {
    top: 54px;
    right: 0;
    padding: 0;
    width: 100%;
    padding: 0 15px;
    max-width: none;
  }
}

.group {
  position: relative;
  width: 100%;
}
