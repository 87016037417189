.wrapper {
  padding-top: 100px;
  padding-bottom: 10px;
  margin: auto;
  width: 300px;
  text-align: center;
}
.progressTitle {
  font-size: 20px;
  font-weight: bold;
}
.text {
  display: block;
  margin-top: 20px;
  font-size: 14px;
}
.timer{
  display: block;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 500;
  color: hsl(240 8% 9% / 0.9) !important;
}
.progress {
  background-color: #d2d9f0;
  border-radius: 5px;
  box-shadow: none;
  height: 10px;
  margin-top: 5px;
}
.progressBar {
  margin-top: 20px;
  background-color: #00b1ff !important;
  border-radius: 5px;
  box-shadow: none;
  height: 10px;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 16px 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 16px 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress.active .progressBar,
.progressBar.active {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}
.progressStriped .progressBar,
.progressBarStriped {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.4) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0.4) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.4) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.4) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.4) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0.4) 75%,
    transparent 75%,
    transparent
  );
  background-size: 16px 16px;
}

.notFound {
  background-color: red;
  border-radius: 5px;
  box-shadow: none;
  height: 10px;
  margin-top: 5px;
}
