@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.5/dist/web/static/pretendard-dynamic-subset.css");


$app-fg: #11152c;
// $app-bg: #2043b5;
$app-bg: #00b1ff;
$brand: hsl(240 8% 9% / 0.9);
$link: hsl(210, 100%, 70%);
$link-bg: hsl(210 100% 70%);
$line: #d8ddf0;
$line: hsla(210, 11%, 7%, 0.09);
$bg-hover: fade-out($brand, 0.98);
$a-bg: hsl(210 100% 70% / 0.1);


$green: hsl(168 80% 45%);
$red: hsl(347, 100%, 60%);
$orange: hsl(36 98% 50%);
$navy: hsl(220, 89%, 11%);
$sky: #00b1ff;
$table-head: hsl(214, 28%, 95%);
$table-head: hsl(240, 20%, 97%);
/* Sizes */
$breakpoint: 960px;
$breakpoint-large: 1024px;
$gutter: 20px;
$gutter-large: 30px;
$radius: 5px;
$max-width: (1180px + (2 * $gutter-large));
